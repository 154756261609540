// extracted by mini-css-extract-plugin
export var hero = "styles-module--hero--3q5nv";
export var content = "styles-module--content--1DUsD";
export var removeMargin = "styles-module--removeMargin--3ppmz";
export var slider = "styles-module--slider--2EzMV";
export var image = "styles-module--image--OeiTl";
export var video = "styles-module--video--xVJeI";
export var logo = "styles-module--logo--UG_-C";
export var title = "styles-module--title--1PU4n";
export var dek = "styles-module--dek--1yH45";
export var bounce = "styles-module--bounce--3sPM7";