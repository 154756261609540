import React from 'react';
// import docu from "./docu.html";

const Description = ({
    bodyText, button
  }) => (
        <div>   
            <div id="bodyText">
                <p>{bodyText}</p>
            </div>
        </div>
);

export default Description;
