import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';
import Img from 'gatsby-image';
import TlmdLogo from '../../assets/images/telemundo-logo.png';
import * as styles from './styles.module.scss';

const Hero = ({
    className,
    styleOverride,
    removeMargin,
    title,
    dek,
    gallery,
  }) => {
    const sliderSettings = {
      className: styles.slider,
      draggable: true,
      dots: true,
      autoplay: true,
      fade: true,
      swipe: true,
      horizontalSwiping: true,
      infinite: true,
      arrows: true,
      pauseOnHover: false,
      speed: 800,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows:false,
            fade:false,
            dots:false,
            swipe:true,
            draggable: true,
            horizontalSwiping: true,
            touchThreshold:1000,
            swipeToSlide: true
          }
        }
      ]
    };
  
    const isMobile = useMediaQuery({ query: '(max-width: 757px)' });
  
    return (
      <div className={[styles.hero, className, removeMargin && styles.removeMargin].join(' ')}>
        <div className={styles.content}>
  
          {/* {dek && <p className={styles.dek} dangerouslySetInnerHTML={{ __html: dek }} />} */}
        </div>
  
        <Slider {...sliderSettings}>
          { gallery.map((slide) => (
            <div key={slide.id} className={styles.slide}>
              { slide.video && !isMobile
                ? (
                  <video
                    className={styles.video}
                    poster={slide.image.childImageSharp.fluid}
                    controls
                    autoPlay
                    loop
                    muted
                  >
                    <source src={slide.video} type="video/mp4" />
                    <track default kind="captions" srcLang="en" src="" />
                  </video>
                )
                : <Img className={styles.image} fluid={slide.image.childImageSharp.fluid} /> }
            </div>
          ))}
        </Slider>
      </div>
    );
  };
  
  Hero.propTypes = {
    className: PropTypes.string,
    styleOverride: PropTypes.shape({
      title: PropTypes.string,
    }),
    removeMargin: PropTypes.bool,
    title: PropTypes.string,
    logo: PropTypes.string,
    dek: PropTypes.string,
    gallery: PropTypes.arrayOf(PropTypes.object),
  };
  
  Hero.defaultProps = {
    className: null,
    styleOverride: {
      title: '',
    },
    removeMargin: false,
    title: null,
    logo: null,
    dek: null,
    gallery: [],
  };
  
  export default Hero;